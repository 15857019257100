import React, { useState } from 'react';
import './Login.css'; // Import stylów

const API_URL = process.env.REACT_APP_API_URL || 'http://avisierung.de';

const Login = ({ onLogin }) => {
    const [firstName, setFirstName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${API_URL}/login`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ firstName, password }),
            });

            const data = await response.json();

            if (response.ok) {
                onLogin(data); // Przekazuje dane użytkownika do `App.js`
            } else {
                setError(data.message || 'Błąd logowania');
            }
        } catch (err) {
            setError('Błąd połączenia z serwerem');
        }
    };

    return (
        <form className="login-form" onSubmit={handleLogin}>
            <h1>anmelden</h1>
            <div className="form-group">
                <label>Vorname:</label>
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
            </div>
            <div className="form-group">
                <label>Paswword:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Zaloguj</button>
            {error && <p className="error-message">{error}</p>}
        </form>
    );
};

export default Login;
