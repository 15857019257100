import React from 'react'; // Importuje React
import ReactDOM from 'react-dom/client';
import './index.css'; // Załóżmy, że masz plik CSS
import App from './App'; // Importuje główny komponent App
import reportWebVitals from './reportWebVitals'; // Importuje reportWebVitals, jeśli jest używane
import 'bootstrap/dist/css/bootstrap.min.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);



// Jeśli używasz reportWebVitals, pozostaw tę część, jeśli nie - możesz ją usunąć.
reportWebVitals();
