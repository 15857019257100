import React, { useState, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import MyContext from './MyContext'; // Import kontekstu
import HomePage from './HomePage';
import Unternehmen from './Unternehmen';
import Mitarbeiter from './Mitarbeiter';
import Baustellenausweise from './Baustellenausweise';
import Prufung from './Prufung';
import Lesegerat from './Lesegerat';
import Einladungen from './Einladungen';
import Anweisenlog from './Anweisenlog';
import Anweisenliste from './Anweisenliste';
import Reports from './Reports';
import StickyMenu from './StickyMenu';
import Login from './common/Login';
import AddUnternehmenForm from './common/AddUnternehmenForm';

const router = createBrowserRouter([
    { path: "/", element: <HomePage /> },
    { path: "/Unternehmen", element: <Unternehmen /> },
    { path: "/Mitarbeiter", element: <Mitarbeiter /> },
    { path: "/Baustellenausweise", element: <Baustellenausweise /> },
    { path: "/Prufung", element: <Prufung /> },
    { path: "/Lesegerat", element: <Lesegerat /> },
    { path: "/Einladungen", element: <Einladungen /> },
    { path: "/Anweisenlog", element: <Anweisenlog /> },
    { path: "/Anweisenliste", element: <Anweisenliste /> },
    { path: "/Reports", element: <Reports /> },
    { path: "/add-unternehmen", element: <AddUnternehmenForm /> },
]);

function App() {
    const [user, setUser] = useState(null);

    // Funkcja do logowania
    const handleLogin = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData)); // Zapis do localStorage
    };

    // Funkcja do wylogowania
    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user'); // Usunięcie danych z localStorage
    };

    // Sprawdzenie stanu logowania przy uruchomieniu aplikacji
    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    return (
       <MyContext.Provider value={{ user: user || {}, handleLogin, handleLogout }}>
    {user ? (
        <>
            <StickyMenu />
            <RouterProvider router={router} />
        </>
    ) : (
        <Login onLogin={handleLogin} />
    )}
</MyContext.Provider>



    );
}

export default App;
