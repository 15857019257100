import React, { useContext } from 'react';
import MyContext from './MyContext';
import MenuAdmin from './menu/admin/MenuAdmin';
import MenuBaucher from './menu/baucher/MenuBaucher';
import MenuWachman from './menu/wachmann/MenuWachman';

const StickyMenu = () => {
    const { user, handleLogout } = useContext(MyContext);

    const renderMenuByRole = () => {
        if (!user || !user.role) {
            console.error("Użytkownik lub rola nie jest zdefiniowana.");
            return <div>Brak dostępu do menu.</div>;
        }

        switch (user.role) {
            case 'Admin':
                return <MenuAdmin />;
            case 'Baucher':
                return <MenuBaucher />;
            case 'Wachman':
                return <MenuWachman />;
            default:
                return <div>Nieznana rola: {user.role}</div>;
        }
    };

    return <div className="sticky-menu">{renderMenuByRole()}</div>;
};

export default StickyMenu;
