// Przykładowy plik AddUnternehmenForm.js
import React, { useState } from 'react';

const AddUnternehmenForm = () => {
    const [data, setData] = useState({
        unternehmen: '',
        strasse: '',
        plz: '',
        stadt: '',
        telefon: '',
        email: ''
        // Dodaj więcej stanów zależnie od potrzeb
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logika wysyłania danych do serwera
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="unternehmen" value={data.unternehmen} onChange={handleChange} />
            {/* Dodaj pozostałe pola */}
            <button type="submit">Dodaj Unternehmen</button>
        </form>
    );
};

export default AddUnternehmenForm;
